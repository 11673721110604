import React, { Component } from 'react';
import { connect } from 'react-redux';

import ResetPasswordView from "views/Website/Entry/ResetPasswordView"
import ResultView from "../ResultView";

import * as cleanse from "store/actions/cleanse";
import * as notice from "store/actions/notice";
import { requestcode } from 'store/actions/resetcode.js';
import { reset } from 'store/actions/resetpassword';
// import { update } from 'variables/utilities';
import { updatestate } from 'variables/utilities';

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		// state related data container
		this.state = {
			data: {
					email: this.props.email && this.props.email.length ? this.props.email : '',
					password: '',
					confirmpassword: '',
					code: '',
			},
			showotp: false,
			showresult: false,
			valid: {},
			emailprovided: this.props.email && this.props.email.length,
			modified: new Date(),
		}
	}

  static getDerivedStateFromProps(props, state) {
		// some change in state, lets check whats changed
		if(props.modified > state.modified || props.error !== null) {
			const { error } = props;
			const showotp = error !== "007" && props.showotp;
			return {
				showotp: showotp,
				showresult: props.showresult,
				modified: props.modified,
			}
		}
		return null;
	}
	
	// initialization of the component
	componentDidMount () {
		this.props.scrub();
	}
 
  onChange = (event, attribute, type, valid=true) => {
		this.setState(updatestate(this.state, event, attribute, type, valid));
  }
	
	onSubmit = (event) => {
		event.preventDefault();
    // check if there are any validation errors
    const valid = this.validate();
    const failed = Object.values(valid).find((value) => value === false);

    if(failed === false) {
      this.setState({valid: valid});
      this.props.notify("999");
    } else {
			let passcodeType = this.props.type;
			passcodeType = (!passcodeType || passcodeType === "Forgot") ? 'forgot' : 'reset';
			if(this.state.showotp === false) {
					this.props.requestcode(this.state.data.email, passcodeType);
			} else {
					this.props.reset(this.state.data.email, this.state.data.password,
															this.state.data.code);
			}
		}
	}

	validate = () => {
		const valid = this.state.valid;

		if(this.state.showotp === false) {
			if(this.state.data.email === '') {
				valid.email = false;
			}
			
			if(this.state.data.password === '') {
				valid.password = false;
			}

			if(this.state.data.confirmpassword === '') {
				valid.confirmpassword = false;
			}
		} else {
			if(this.state.data.code === '') {
				valid.code = false;
			}
		}

		return valid;
	}

	// the view of the component that needs to be rendered
	render () {
		if(!this.props.showresult) {
				return (
					<ResetPasswordView
							{...this.state.data}
							valid={this.state.valid}
							showotp={this.state.showotp}
							emailprovided={this.state.emailprovided}
							dashboard={this.state.emailprovided}
							onChange={this.onChange}
							onSubmit={this.onSubmit}
					/>);
			} else {
				const subtitle = "Your password has been set, you can now login to " + process.env.REACT_APP_WEBSITE_NAME;
				return (
					<ResultView
							{...this.props}
							title="Reset Password"
							infotitle="Password has been set successfully"
							infosubtitle={subtitle}
							dashboard={this.state.emailprovided}
							onChangeHandler={this.props.onChangeHandler}
							onSubmitHandler={this.onSubmit.bind(this)}
					/>);
			}
	}
}

const mapStateToProps = state => {	
	const resetpasswordmodified = state.resetpassword.modified ? state.resetpassword.modified : -1;
	const modified = 	 state.resetcode.modified > resetpasswordmodified ?
													state.resetcode.modified : state.resetpassword.modified;
	const showotp = state.resetcode.modified > resetpasswordmodified 
											&& state.resetcode.error === null && !state.resetcode.loading;											
	const showresult =  resetpasswordmodified > state.resetcode.modified
											&& state.resetpassword.error === null && !state.resetpassword.loading;						
	const error = state.resetcode.error !== null ?
													state.resetcode.error : state.resetpassword.error;
	return {
		showotp: showotp,
		showresult: showresult,
		modified: modified,
		error: error,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		scrub: () => dispatch(cleanse.scrubPasswordState()),
		notify: (code) => dispatch(notice.notifyFailure(code)),
		requestcode: (email, type) => dispatch(requestcode(email, type)),
		reset: (email, password, code) => dispatch(reset(email, password, code))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);