import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	profile: null, company: null,
	error: null, request: null,
	loading: false, notify: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true,
		changepassword: false,
	});
};

const succeeded = (state, action) => {
	const company = action.company ? action.company : state.company;
	return update( state, {
		profile: action.profile, company: company,
		loading: false, notify: true,
		changepassword: false,
	});
};

const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.USER_START: 
			return started(state, action);

		case types.USER_SUCCESS: 
			return succeeded(state, action);

		case types.USER_FAILURE: 
			return failed(state, action);

		case "CLOSE": 
			return {notify: false};

		case types.USER_SHOW_CHANGE_PASSWORD: 
			return {changepassword: action.changepassword};

		default:
			return state;
	}
};

export default reducer;