
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authorize from 'store/reducers/authorize';
import resetcode from  'store/reducers/resetcode';
import resetpassword from 'store/reducers/resetpassword';
import enquiry from 'store/reducers/enquiry';
import register from 'store/reducers/register';
import registerconfirm from  'store/reducers/registerconfirm';
import demo from 'store/reducers/demo';


import userprofile from 'store/reducers/userprofile';
import personalization from 'store/reducers/personalization';
import company from 'store/reducers/businessprofile';
import notice from 'store/reducers/notice';
import crumbs from 'store/reducers/crumbs';
import partner from 'store/reducers/partner';
import contact from 'store/reducers/contact';
import building from 'store/reducers/building';
import artifact from 'store/reducers/artifact';
import employee from 'store/reducers/employee';
import audits from 'store/reducers/audits';
import interaction from 'store/reducers/interaction';
import search from 'store/reducers/search';
import filters from 'store/reducers/filters';
import progress from 'store/reducers/progress';
import tenant from 'store/reducers/tenant';
import requirement from 'store/reducers/requirement';
import unit from 'store/reducers/unit';
import proposal from 'store/reducers/proposal';
import proposalunit from 'store/reducers/proposalunit';
import proposalhistory from 'store/reducers/proposalhistory';
import toolbar from 'store/reducers/toolbar';
import template from 'common/store/reducers/template';
import settings from 'store/reducers/settings';
import notifications from 'store/reducers/notifications';
import addons from 'store/reducers/addons';
import library from 'store/reducers/library';
import stats from 'common/store/reducers/stats';

const reducers = combineReducers({
  notice: notice,
  crumbs: crumbs,
  toolbar: toolbar,
  authorize: authorize,
  resetcode: resetcode,
  resetpassword: resetpassword,
  enquiry: enquiry,
  register: register,
  registerconfirm: registerconfirm,
  demo: demo,
  
  userprofile: userprofile,
  personalization: personalization,
  partner: partner,
  contact: contact,
  interaction: interaction,
  employee: employee,
  addons: addons,
  library: library,
  audits: audits,
  notifications: notifications,
  building: building,
  artifact: artifact,
  company: company,
  settings: settings,
  search: search,
  filters: filters,
  progress: progress,
  tenant: tenant,
  requirement: requirement,
  unit: unit,
  proposal: proposal,
  proposalunit: proposalunit,
  proposalhistory: proposalhistory,
  template: template,
  stats: stats,
});

const rootreducer = (state, action) => {
  if(action.type === "AUTHORIZE_LOGOUT") {
    state = undefined;
  }

  return reducers(state, action);
}

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}

const store = createStore(rootreducer, composeEnhancers(
  applyMiddleware(thunk)
));

export default function createStores() {
    return (store);
}