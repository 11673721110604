import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null, audits: {},
	loading: false, audit: null,
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	var notifications = action.notifications ? action.notifications : state.notifications;
	if(action.index >= 0) {
		if(action.notification) {
			notifications[action.index]=action.notification;
		} else {
			notifications.splice(action.index, 1);
		}
	}
	return update( state, {
		notifications: notifications,
		modifiedDate: action.modifiedDate ? action.modifiedDate : state.modifiedDate,
		loading: false, notify: true
	});
};

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const notifications = (state = initial, action) => {
	switch ( action.type ) {
		case types.NOTIFICATION_START: 
			return started(state, action);

		case types.NOTIFICATION_SUCCESS: 
			return succeeded(state, action);

		case types.NOTIFICATION_FAILURE: 
      return failed(state, action);
      
		default:
			return state;
	}
};

export default notifications;