import * as types from "store/types.js";
import { update } from 'variables/utilities';

const initial = {
  loading: false, request: null,
  modified: null, error: null
};

const started = (state, action) => {
  return update( state, {
          request: action.request, error: null, 
          loading: true 
  });
};

const succeeded = (state, action) => {
  return update( state, {
          error: null, loading: false, modified: new Date(),
   });
};

const failed = (state, action) => {
  return update( state, {
          error: action.error, loading: false
  });
}

const scrub = (state, action) => {
  return update( state, {
          error: null, 
          modified: -1, 
  });
};

const resetcode = (state = initial, action) => {
  switch ( action.type ) {
    case types.RESETCODE_START: 
      return started(state, action);

    case types.RESETCODE_SUCCESS: 
      return succeeded(state, action);

    case types.RESETCODE_FAILURE: 
      return failed(state, action);

    case types.CLEAR_PASSWORD_STATE: 
      return scrub(state, action);

    default:
      return state;
  }
};

export default resetcode;