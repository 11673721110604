import axios from "store/axios-broker";
import * as types from "store/types.js";
import { show as notifySuccess } from "store/actions/notice";

const started = (request) => {
  return {
      type: types.RESETCODE_START,
      request: request,
      error: null
  };
};

const succeeded = () => {
  return {
      type: types.RESETCODE_SUCCESS,
      error: null
  };
};

const failed = (error) => {
  return {
      type: types.RESETCODE_FAILURE,
      error: error && error.data && error.data.code ? error.data.code : "000"
  };
};

const querystring = require('querystring');
export const requestcode = (email, type, token=null) => {
  return dispatch => {
    dispatch(started('requestcode'));
    const body = {
        alias: email
    };
    axios.post("/apex/certify/password/" + email + "/" + type + "/code",
                querystring.stringify(body), { urlencoded: true  }
    ).then(response => {
      dispatch(succeeded());
      dispatch(notifySuccess("otp"));
    }).catch(error => {
      dispatch(failed(error.response));
    });
  };
};