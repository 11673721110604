import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
  searchText: '',
  paths: [],
  crumbs: {},
};

const setState = (state, action) => {
  return update({
    searchText: action.searchText,
    searchType: action.searchType,
    searchVisible: action.searchVisible,
    home: action.home,
    crumbs: action.crumbs,
    showncrumbs: action.showncrumbs,
    modifiedDate: new Date(),
  })
}

const updateState = (state, action) => {
  return update({
    searchVisible: action.searchVisible,
    showncrumbs: action.showncrumbs,
    home: action.home,
    crumbs: action.crumbs,
    modifiedDate: new Date(),
  })
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.TOOLBAR_STATE: 
      return setState(state, action);
    
    case types.TOOLBAR_UPDATE:
      return updateState(state, action);

		default:
			return state;
	}
};

export default reducer;