import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const interactions = (state.request === 'retrieve' && action.interactions !== null) 
												? action.interactions : state.interactions;
  if(action.interaction) {
    // need to merge the changes with the list...  
	}
	return update( state, {
		interactions: interactions,
		interaction: action.interaction,
		modifiedDate: new Date(),
		loading: false
	});
};

const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const removed = (state, action) => {
	return update( state, {
			interaction: null, loading: false
	});
}

const interaction = (state = initial, action) => {
	switch ( action.type ) {
		case types.INTERACTION_START: 
			return started(state, action);

		case types.INTERACTION_SUCCESS: 
			return succeeded(state, action);

		case types.INTERACTION_FAILURE: 
			return failed(state, action);

			case types.INTERACTION_REMOVE: 
			return removed(state, action);

		default:
			return state;
	}
};

export default interaction;