import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, Tooltip } from "@material-ui/core";

import {
  grayColor,
  successColor,
  dangerColor,
  infoColor,
  warningColor,
} from "core/assets/jss/material-dashboard-pro-react.js";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Supervisor from "@material-ui/icons/SupervisedUserCircle";
import NoteIcon from "@material-ui/icons/Note";
import ConferenceIcon from "@material-ui/icons/Group";
import MeetingIcon from "@material-ui/icons/CalendarToday";
import CallIcon from "@material-ui/icons/Call";
import Business from "@material-ui/icons/Business";
import LinkIcon from "@material-ui/icons/Link";
import CloudDownload from "@material-ui/icons/CloudDownload";

// proposal status icons
import AcceptedIcon from "@material-ui/icons/ThumbUp";
import RejectedIcon from "@material-ui/icons/ThumbDown";
import ClarificationIcon from "@material-ui/icons/Comment";
import RemovedIcon from "@material-ui/icons/RemoveCircleOutline";
import ViewCommentsIcon from "@material-ui/icons/QuestionAnswer";
import RecommendIcon from "@material-ui/icons/Flag";

// asset owner type icons
import BuildingIcon from "@material-ui/icons/AccountBalance"
import UnitIcon from "@material-ui/icons/Extension";

// @material-ui icons
import CoverAssetIcon  from "@material-ui/icons/Favorite";
import NormalAssetIcon  from "@material-ui/icons/FavoriteBorder";
import PowerIcon  from "@material-ui/icons/PowerSettingsNew";
import ChangeIcon  from "@material-ui/icons/ShoppingCart";

import ProposalUnitIcon from "@material-ui/icons/ShoppingCart";
import AddProposalUnitIcon from "@material-ui/icons/AddShoppingCart";
import RemoveProposalUnitIcon from "@material-ui/icons/RemoveShoppingCart";

import CheckedBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineIcon from "@material-ui/icons/CheckBoxOutlineBlank";

import LogoutIcon from "@material-ui/icons/ExitToApp";

import OpenWith from "@material-ui/icons/PhotoSizeSelectActualOutlined";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// core components
import Button from "core/components/CustomButtons/Button.js";

import styles from "core/assets/jss/material-dashboard-pro-react/views/utilitiesStyle.js";
const useStyles = makeStyles(styles);

export const makeDownloadLink = (artifact, path) => {
  return <DownloadLink key={artifact.dossierId} name={artifact.name} type={artifact.traits.type} path={path} />
}

function DownloadLink(props) {
  const { name, path, type } = props;
	const classes = useStyles();
	return (
    // <Tooltip key={key} id="tooltip-top" title="Mark as cover asset" 
    //           placement="bottom" classes={{tooltip: tooltip}}>
    type === 'Link' ? 
      <a href={name} target="blank" className={classes.actionButton}><LinkIcon className={classes.largeicon} /></a> : 
      <a download={name} href={path} className={classes.actionButton}><CloudDownload className={classes.largeicon} /></a>
    // </Tooltip>
	);
}

export function useTitle(title) {
  React.useEffect(() => {
    const prevTitle = document.title
    document.title = title
    return () => {
      document.title = prevTitle
    }
  })
}

export const makeCoverAssetAction = (cover, key, onClick) => {
  return (
    <CoverAssetAction key={key} cover={cover} onClick={onClick} />
  );
}

function CoverAssetAction(props) {
  const { cover } = props;
	const classes = useStyles();
	return (
    <Tooltip id="tooltip-top" 
              title="Mark as cover asset" placement="top">
      <Button simple color="primary" {...props} className={classes.actionButton}>
        {cover ? (<CoverAssetIcon className={classes.largeicon} />) : 
                  (<NormalAssetIcon className={classes.largeicon} />)}
      </Button>
    </Tooltip>
	);
}

export function ViewExpandedImage(props) {
	const classes = useStyles();
	return (
    <Tooltip id="tooltip-top" 
              title="View image asset" placement="top">
      <Button simple color="info" {...props} className={classes.actionButton}>
        <OpenWith className={classes.icon} />
      </Button>
    </Tooltip>
	);
}

export function Action(props) {
	const classes = useStyles();
  const { tooltip="Click button to perform action", placement="top", color="info" } = props;
	return (
    <Tooltip id={ props.id ? props.id + ".tooltip" : undefined } 
              title={tooltip} placement={placement}
              classes={{ tooltip: classes.tooltip }}>              
      <Button simple color={color} {...props} className={classes.actionButton}>
        { props.icon ?
          <props.icon className={classes.icon} /> :
          <OpenWith className={classes.icon} /> }
      </Button>
    </Tooltip>
	);
}

export const makeTableCheckBox = (key, disabled, onClick) => {
  return (
    <TableCheckBox key={key} disabled={disabled} onClick={onClick} />
  );
}

function TableCheckBox(props) {
  const [checked, setChecked] = React.useState(false);
	const classes = useStyles();
	return (
    <Button simple color={checked ? "primary" : "transparent"}
            {...props} 
            onClick={() => {
              setChecked(!checked)
              props.onClick(checked)
            }}
            className={classes.actionButton}>
      {checked ? (<CheckedBoxIcon className={classes.largeicon} />) : 
                  (<CheckBoxOutlineIcon className={classes.largeicon} />)}
    </Button>
	);
}

const makeLink = (display, path, state={}, queryParams='') => {
  const segment = window.location.pathname.endsWith("/") ? path : "/" + path;
	return (
		<div>
			<Link to={{pathname: window.location.pathname + segment, search: queryParams, state: state}}>
				{display}
			</Link>
		</div>
	);
}

const makePartnerIcon = (traits, type) => {
  // if(type === 'Client') {
    if(traits != null && traits.category === 'individual') {
      return <StyledIcon icon={Person} />
    }
  // }
  // if(type === 'Landlord') {
    if(traits != null && traits.category === 'business') {
      return <StyledIcon icon={Business} />
    }
  // }
	return <div></div>
}

const makeSupervisorIcon = (role) => {
  if(role === 'Administrator') {
    return <StyledIcon icon={Supervisor} />
  }
  return null;
}

const makePrimaryContact = (traits) => {
	if(traits !== null && traits.primary && traits.primary.includes('Primary contact')) {
		return <StyledIcon icon={Person} style={{color: successColor[0]}} />
	}
	return <div></div>
}

const makeProposalUnitIcon = (key, status) => {
  return <ProposalUnitIcon key={key} style={{ color: warningColor[1] }} />;
}

const makeProposalUnitAddAction = (key, onClick) => {
  if(onClick) {
    return <AddAction key={key} onClick={onClick}/>
  }
  return null;
}

export function AddAction(props) {
  const { color="success" } = props;
	const classes = useStyles();
	return (
		<Button simple {...props} color={color} className={classes.actionButton}>
			<AddProposalUnitIcon className={classes.icon} />
		</Button>
	);
}

const makeProposalUnitViewCommentAction = (key, onClick) => {
  if(onClick) {
    return <ProposalUnitViewCommentAction key={key} onClick={onClick}/>
  }
  return null;
}

function ProposalUnitViewCommentAction(prop) {
	const classes = useStyles();
	return (
		<Button simple color="info" {...prop} className={classes.actionButton}>
			<ViewCommentsIcon className={classes.icon} />
		</Button>
	);
}

const makeProposalUnitRecommendAction = (key, flagged, onClick) => {
  if(onClick) {
    return <ProposalUnitRecommendAction key={key} flagged={flagged ? "flagged" : undefined} onClick={onClick}/>
  }
  return null;
}

function ProposalUnitRecommendAction(prop) {
	const classes = useStyles();
	return (
		<Button simple color={prop.flagged === "flagged" ? "success" : "gray"} {...prop} className={classes.actionButton}>
			<RecommendIcon className={classes.icon} />
		</Button>
	);
}

const makeProposalUnitRemoveAction = (key, onClick) => {
  if(onClick) {
    return <ProposalUnitRemoveAction key={key} onClick={onClick}/>
  }
  return null;
}

function ProposalUnitRemoveAction(prop) {
	const classes = useStyles();
	return (
		<Button simple color="success" {...prop} className={classes.actionButton}>
			<RemoveProposalUnitIcon className={classes.icon} />
		</Button>
	);
}

const makeDashboardConfigAction = (key, subscribed, onClick) => {
  return <DashboardConfigAction key={key} subscribed={subscribed} onClick={onClick}/>
}

function DashboardConfigAction(props) {
  const { subscribed, ...rest } = props;
	const classes = useStyles();
	return (
		<Button simple color={subscribed ? "success" : "danger"} {...rest} className={classes.actionButton}>
			<PowerIcon className={classes.icon} />
		</Button>
	);
}

const makeAddonChangeUnitsAction = (key, onClick) => {
  return <AddonChangeUnitAction key={key} onClick={onClick}/>
}

function AddonChangeUnitAction(props) {
	const classes = useStyles();
	return (
		<Button simple color={"info"} {...props} className={classes.actionButton}>
			<ChangeIcon className={classes.icon} />
		</Button>
	);
}

const makeEmployeeLogoutAction = (indexKey, onClick) => {
  if(onClick) {
    return <EmployeeLogoutAction onClick={onClick}/>
  }
  return null;
}

function EmployeeLogoutAction(props) {
	const classes = useStyles();
	return (
    <Tooltip id="tooltip-top" key={props.key} title="Logout user session" placement="top">
      <Button simple color="danger" {...props} className={classes.actionButton}>
        <LogoutIcon className={classes.icon} />
      </Button>
    </Tooltip>
	);
}

const makeInteractionTypeColor = (type) => {
  switch(type) {
    case "Note":
      return "azure";

    case "Conference":
      return "rose";
    
    case "Meeting":
      return "orange";
    
    case "Call":
      return "green";

    case "Others":
      return "red" ;
       
    default:
      return "default";
  }
}

function StyledIcon(props) {
  const { style } = props;
  const classes = useStyles();
  return <props.icon style={style} className={classes.icon} />
}

const makeInteractionTypeIcon = (type) => {
  switch(type) {
    case "Note":
      return <StyledIcon icon={NoteIcon} style={{ color: grayColor[1] }} />;

    case "Conference":
      return <StyledIcon icon={ConferenceIcon} style={{ color: grayColor[1] }} />;
    
    case "Meeting":
      return <StyledIcon icon={MeetingIcon} style={{ color: grayColor[1] }} />;
    
    case "Call":
      return <StyledIcon icon={CallIcon} style={{ color: grayColor[1] }} />;

    case "Others":
      return <FontAwesomeIcon icon="handshake" color="gray" size="1x"/> ;
       
    default:
      return null;
  }
}

const makeProposalStatusIcon = (status) => {
  switch(status) {
    case "Accepted":
      return <StyledIcon icon={AcceptedIcon} style={{ color: successColor[1] }} />;

    case "Rejected":
      return <StyledIcon icon={RejectedIcon} style={{ color: dangerColor[1] }} />;

    case "Clarification":
      return <StyledIcon icon={ClarificationIcon} style={{ color: infoColor[1] }} />;

    case "Removed":
      return <StyledIcon icon={RemovedIcon} style={{ color: warningColor[1] }} />;
      
    default:
      return null;
  }
}

function AssetOwnerTypeIcon(props) {
  return (
    <div>
    { props.ownertype === 'building' ? 
    <StyledIcon icon={BuildingIcon} style={{ color: grayColor[1] }} /> :
    <StyledIcon icon={UnitIcon} style={{ color: grayColor[1] }} />
    }
    </div>
  );
}

export {
    makeLink,
    makePartnerIcon,
    makeSupervisorIcon,
    makePrimaryContact,
    makeProposalUnitAddAction,
    makeProposalUnitRemoveAction,
    makeProposalUnitViewCommentAction,
    makeProposalUnitRecommendAction,
    makeInteractionTypeColor,
    makeInteractionTypeIcon,
    makeProposalUnitIcon,
    makeProposalStatusIcon,
    AssetOwnerTypeIcon,
    makeDashboardConfigAction,
    makeAddonChangeUnitsAction,
    makeEmployeeLogoutAction,
};

export * from "core/utils/utilities";