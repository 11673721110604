import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	purchases: null, property: null, error: null,
	loading: null, request: null
};

const started = (state, action) => {
	return update( state, {
		request: action.request, modifiedDate: -1,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const purchases = action.purchases ? action.purchases : state.purchases;

	return update(state, {
		purchases: purchases,
		property: action.property,
		modifiedDate: action.modifiedDate,
	});
};

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.LIBRARY_START: 
			return started(state, action);
				
		case types.LIBRARY_SUCCESS: 
			return succeeded(state, action);
				
		case types.LIBRARY_FAILURE: 
			return failed(state, action);
				
		default:
			return state;
	}
};

export default reducer;