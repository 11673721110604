import React, { lazy, Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from "react-router-dom";

// @material-ui components
import { CircularProgress } from '@material-ui/core';

import WebLayout from 'layouts/WebLayout/WebLayout';

// images, utilities and actions
import backgroundImage from "assets/img/landing/landing-background.webp";
import * as authorization from 'store/actions/authorize';

// lazy load the broker platform part
const AdminLayout = lazy(() => import("layouts/BrokerLayout/AdminLayout"));

function LoadingAdminLayout (props) {
	return (
		<div 
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "100vh",
				color: "black",
				backgroundImage: "url(" + backgroundImage + ")",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center center",
			}}>
				<CircularProgress style={{color: "white"}} />
				<h2 style={{color: "white", padding: "8px"}}>Loading Broker Platform</h2>
		</div>
	)
}

function LazyAdminLayout (props) {
	return (
		<Suspense fallback={<LoadingAdminLayout />}>
			<AdminLayout {...props} />
		</Suspense>
	)
}

class App extends Component {
	
	componentDidMount() {
		this.props.checkAuthorized();
	}
	
	componentDidUpdate(prevProps, prevState) {
		if(this.props.location.pathname === "/" 
					&& prevProps.location.pathname.startsWith(process.env.REACT_APP_BROKER_PLATFORM)) {
			this.props.checkAuthorized();
		}
		// scroll to top on a new page
		if (this.props.location.pathname !== prevProps.location.pathname) {
			const panel = document.getElementById("adminMainPanel");
			if(panel) {
				panel.scrollTo(0,0);
			}
    }
	}

	render() {
		// check whether we need to switch routes to the admin dashboard
		const location = window.location.pathname;
		let authorized = this.props.authorized;
		if(authorized) {		
			// now check whether we are in the sign-in/signup flows
			authorized = process.env.REACT_APP_AUTH_FORGOT !== location
										&& process.env.REACT_APP_AUTH_SIGNUP !== location;
		}

		// default route (non authorized routes)
		let routes = (
			<Switch>
				<Route path={process.env.REACT_APP_BROKER_PLATFORM} component={WebLayout} />
				<Redirect from="/" to={`${process.env.REACT_APP_BROKER_PLATFORM}`} />
			</Switch>
		);
		
		// authorized? so switch the routes to the admin dashboard
		if (authorized) {
			routes = (
				<Switch>
					<Route path={process.env.REACT_APP_BROKER_PLATFORM} component={LazyAdminLayout} />
				  <Redirect from="/" to={process.env.REACT_APP_BROKER_PLATFORM} />
				</Switch>
			)
		}

		return (
			<div>{routes}</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		authorized: state.authorize.userId !== null
	};
};

const mapDispatchToProps = dispatch => {
	return {
		checkAuthorized: () => dispatch(authorization.checkAuthorized()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));