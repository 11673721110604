import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false, notify: false
};

const started = (state, action) => {
	const contacts = (state.request === 'retrieve') 
												? action.contacts : state.contacts;
	return update( state, {
		request: action.request,
		contacts: contacts,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const contacts = (state.request === 'retrieve') 
												? action.contacts : state.contacts;
	const modifiedDate = 	(state.request === 'retrieve') ? new Date() : state.modifiedDate;										
  if(action.updated) {
    // need to merge the changes with the list...  
	}
	return update( state, {
		contacts: contacts,
		clientId: action.clientId,
		updated: action.updated,
		modifiedDate: modifiedDate,
		loading: false, notify: true
	});
};

const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.CONTACT_START: 
			return started(state, action);

		case types.CONTACT_SUCCESS: 
			return succeeded(state, action);

		case types.CONTACT_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default reducer;