import React, { Component } from 'react';
import { connect } from 'react-redux';

// echelon components
import LoginView from "views/Website/Entry/LoginView"

// echelon commons/utilities
import { updatestate } from 'variables/utilities';

// state management and actions 
import * as authorization from 'store/actions/authorize';
import * as notice from "store/actions/notice";

class Login extends Component {
	constructor(props) {
		super(props);
		
		// state related data container
		this.state = {
			basic: {
				email: '',
				password: '',
				remember: false
			},

			// state management
			valid: {},
		}
	}

	// initialization of the component
	componentDidMount() {
	}

  onChange = (event, attribute, type, valid=true) => {
		this.setState(updatestate(this.state, event, attribute, type, valid));
  }

	onSubmit = () => {
		// perform the necessary validation and show the error message if required
    const valid = this.validate();
    const proceed = Object.values(valid).find((value) => value === false);
		if(proceed === false) {
      this.setState({valid: valid});
			this.props.notify("999");
			
			return;
		}

		const { basic } = this.state;
		this.props.authorize(basic.email, basic.password);
	}

	validate = () => {
		const { basic, valid } = this.state;
		if(basic.email === '') {
			valid.email = false;
		}

		if(basic.password === '') {
			valid.password = false;
		}
	
		return valid;			
	}

	// the view of the component that needs to be rendered
	render() {
		const { basic, valid } = this.state;
		return (
			<div>
				<LoginView
					{...this.props}
					basic={basic}
					valid={valid}
					onChangeHandler={this.onChange}
					onSubmitHandler={this.onSubmit}
				/>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
	};
};

const mapDispatchToProps = dispatch => {
	return {
		notify: (code) => dispatch(notice.notifyFailure(code)),
		authorize: (email, password) => dispatch(authorization.authorize(email, password))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);