import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	return update( state, {
		employee: action.employee,
		employees: action.employees ? action.employees : state.employees,
		modifiedDate: action.employees ? new Date() : state.modifiedDate,
		loading: false
	});
};

const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const removed = (state, action) => {
	return update(state, {
		employee: null,
	});
}

const employment = (state = initial, action) => {
	switch ( action.type ) {
		case types.EMPLOYEE_START: 
			return started(state, action);

		case types.EMPLOYEE_SUCCESS: 
			return succeeded(state, action);

		case types.EMPLOYEE_FAILURE: 
			return failed(state, action);

		case types.EMPLOYEE_REMOVE: 
			return removed(state, action);

		default:
			return state;
	}
};

export default employment;