// contact action types 
export const CONTACT_START = 'CONTACT_START';
export const CONTACT_SUCCESS = 'CONTACT_SUCCESS';
export const CONTACT_REMOVE = 'CONTACT_REMOVE';
export const CONTACT_FAILURE = 'CONTACT_FAILURE';

// employee action types 
export const EMPLOYEE_START = 'EMPLOYEE_START';
export const EMPLOYEE_SUCCESS = 'EMPLOYEE_SUCCESS';
export const EMPLOYEE_REMOVE = 'EMPLOYEE_REMOVE';
export const EMPLOYEE_FAILURE = 'EMPLOYEE_FAILURE';

// buidling action types 
export const REQUIREMENT_START = 'REQUIREMENT_START';
export const REQUIREMENT_SUCCESS = 'REQUIREMENT_SUCCESS';
export const REQUIREMENT_REMOVE = 'REQUIREMENT_REMOVE';
export const REQUIREMENT_FAILURE = 'REQUIREMENT_FAILURE';

// template action types 
export const TEMPLATE_START = 'TEMPLATE_START';
export const TEMPLATE_SUCCESS = 'TEMPLATE_SUCCESS';
export const TEMPLATE_REMOVE = 'TEMPLATE_REMOVE';
export const TEMPLATE_FAILURE = 'TEMPLATE_FAILURE';

// search action types 
export const SEARCH_START = 'SEARCH_START';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const SEARCH_UNIT_SUCCESS = "SEARCH_UNIT_SUCCESS";

// data volume stats
export const STATS_START = 'STATS_START';
export const STATS_SUCCESS = 'STATS_SUCCESS';
export const STATS_FAILURE = 'STATS_FAILURE';
