import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Key from "@material-ui/icons/VpnKey";
import backgroundImage from "assets/img/login-background.jpeg";

// core components
import GridContainer from "core/components/Grid/GridContainer.js";
import GridItem from "core/components/Grid/GridItem.js";
import CustomInput from "core/components/CustomInput/CustomInput.js";
import Button from "core/components/CustomButtons/Button.js";
import Card from "core/components/Card/Card.js";
import CardBody from "core/components/Card/CardBody.js";
import CardHeader from "core/components/Card/CardHeader.js";
import CardFooter from "core/components/Card/CardFooter.js";

import {verifyEmail, verifyPassword,
        verifyPasswordEquals, verifyLength} from "variables/utilities.js";
import { useTitle } from "variables/utilities";

// styles that should be used
import styles from "core/assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
const useStyles = makeStyles(styles);

export default function ResetPasswordView(props) {
  useTitle("Exaspaces, reset password for user");

  // animation for the card entry
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 400);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
 
  const { email, password, confirmpassword, code, showotp, valid, onChange, onSubmit } = props;

  const classes = useStyles();
  const title = props.title != null ? props.title : "Forgot"; 
  return (
    <div>
      <div
        className={classes.fullPage}
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundPosition: "inherit",
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes[cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}>
                    <h1 className={classes.cardH2Title}>{title} Password</h1>
                    <h5 className={classes.cardTitle}>Broker Platform</h5>
                </CardHeader>
                <CardBody>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    success={valid.email === true}
                    error={valid.email === false}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      onChange: event => {
                        const valid = verifyEmail(event.target.value);
                        onChange(event, 'email', 'data', valid);
                      },
                      value: email,
                      disabled: showotp,
                    }}
                  />

                  <CustomInput
                    labelText="Password"
                    id="password"
                    success={valid.password === true}
                    error={valid.password === false}
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: event => {
                        const valid = verifyPassword(event.target.value);
                        onChange(event, 'password', 'data', valid);
                      },
                      disabled: showotp,
                      value: password,
                      type: "password",
                      autoComplete: "off"
                    }}
                  />

                <CustomInput
                    labelText="Confirm Password"
                    helperText="Passwords must be minimum 6 characters long, with at least 1 numeric, 1 uppercase and 1 lowercase character"
                    id="confirmpassword"
                    success={valid.confirmpassword === true}
                    error={valid.confirmpassword === false}
                    formControlProps={{
                      fullWidth: true,
                      required: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: event => {
                        const valid = verifyPasswordEquals(event.target.value, password);
                        onChange(event, 'confirmpassword', 'data', valid);
                      },
                      disabled: showotp,
                      value: confirmpassword,
                      type: "password",
                      autoComplete: "off"
                    }}
                  />

                </CardBody>

                <CardFooter className={classes.justifyContentCenter}>
                  <GridContainer justifyContent="center">
                      <GridItem xs={12} sm={8}>
                        <div className={classes.center}>
                        {showotp && (
                          <div className={classes.center}>
                          <CustomInput
                            id="code"
                            labelText="One-time passcode"
                            success={valid.code === true}
                            error={valid.code === false}
                            formControlProps={{
                              fullWidth: true,
                              required: true,
                              className: classes.customFormControlClasses
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Key className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                              ),
                              onChange: event => {
                                const valid = verifyLength(event.target.value, 6);
                                onChange(event, 'code', 'data', valid);      
                              },
                              value: code,
                              type: "password",
                              autoComplete: "off"
                            }}
                          />
                          </div>)
                        }

                        </div>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.center}>
                          <Button
                            round 
                            fullWidth
                            onClick={onSubmit}
                            color="purple">
                              {props.showotp ? "Set Password" : "Get OTP"}
                          </Button>
                          <p></p>
                        </div>
                      </GridItem>

                  </GridContainer>
                </CardFooter>

              </Card>
            </form>
          </GridItem>
        </GridContainer>
        </div>
      </div>
    </div>
  );
}
