import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const requirements = (state.request === 'retrieve' && action.requirements !== null) 
												? action.requirements : state.requirements;
	return update( state, {
		requirements: requirements,
		requirement: action.requirement,
		modifiedDate: new Date(),
		loading: false
	});
};

const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const removed = (state, action) => {
	return update( state, {
			requirement: null, loading: false
	});
}

const requirements = (state = initial, action) => {
	switch ( action.type ) {
		case types.REQUIREMENT_START: 
			return started(state, action);

		case types.REQUIREMENT_SUCCESS: 
			return succeeded(state, action);

		case types.REQUIREMENT_FAILURE: 
			return failed(state, action);

			case types.REQUIREMENT_REMOVE: 
			return removed(state, action);

		default:
			return state;
	}
};

export default requirements;