import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false, modifiedDate: -1,
};

const started = (state, action) => {
	return update( state, {
    loading: true
	});
};

const succeeded = (state, action) => {
	return update( state, {
		models: action.objectType !== 'building' ? action.models : state.models,
		buildings: action.objectType === 'building' ? action.buildings : state.buildings,
		building: action.objectType === 'building' ? action.building : state.building,
    modifiedDate: action.modifiedDate,
    loading: false,
	});
};

const succeededUnits = (state, action) => {
	const { units, modifiedDate, section } = action;
	return update( state, {
		[section] : {
			units: units,
			unitsModifiedDate: modifiedDate
		}
	});
};


const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const searcher = (state = initial, action) => {
	switch ( action.type ) {
		case types.SEARCH_START: 
			return started(state, action);

		case types.SEARCH_SUCCESS: 
			return succeeded(state, action);

		case types.SEARCH_UNIT_SUCCESS: 
			return succeededUnits(state, action);

		case types.SEARCH_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default searcher;