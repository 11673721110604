import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const units = action.units ? action.units : state.units;
	const addedunits = state.request === 'create' ? [...units, action.unit] : units;
	const index = action.index >= 0 ? action.index : state.index;
	if(state.request === 'update' || state.request === 'flag') {
		addedunits[index] = action.unit;
	}
	return update( state, {
		units: addedunits,
		unit: state.request === 'flag' ? state.unit : action.unit,
		index: state.request === 'flag' ? state.index : index,
		modifiedDate: new Date(),
		loading: false
	});
};

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const clear = (state, action) => {
	return { ...state, ...action };
}

const removed = (state, action) => {
	return update( state, {
		unit: null, units: undefined, 
		loading: false, modifiedDate: new Date(),
	});
}

const proposalunits = (state = initial, action) => {
	switch ( action.type ) {
		case types.PROPOSAL_UNIT_START: 
			return started(state, action);

		case types.PROPOSAL_UNIT_SUCCESS: 
			return succeeded(state, action);

		case types.PROPOSAL_UNIT_REMOVE: 
			return removed(state, action);

		case types.PROPOSAL_UNIT_FAILURE: 
			return failed(state, action);

		case types.PROPOSAL_UNIT_CLEAR: 
			return clear(state, action);

		default:
			return state;
	}
};

export default proposalunits;