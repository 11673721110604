// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

import { faBuilding, faChartArea, faHandshake } from '@fortawesome/free-solid-svg-icons';

library.add(
  faBuilding,
  faChartArea,
  // more icons go here
  faHandshake,
);