import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	profile: null, error: null,
	loading: null, request: null
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	return update( state, {
		profile: action.profile,
		loading: false
	});
};

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.COMPANY_START: 
			return started(state, action);
				
		case types.COMPANY_SUCCESS: 
			return succeeded(state, action);
				
		case types.COMPANY_FAILURE: 
			return failed(state, action);
				
		default:
			return state;
	}
};

export default reducer;