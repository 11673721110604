import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null, audits: {},
	loading: false, audit: null,
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const values = {...state[action.key], ...action[action.key]};
	return update( state, {
		[action.key]: values,
		loading: false, notify: true
	});
};

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const audits = (state = initial, action) => {
	switch ( action.type ) {
		case types.STATS_START: 
			return started(state, action);

		case types.STATS_SUCCESS: 
			return succeeded(state, action);

		case types.STATS_FAILURE: 
      return failed(state, action);
      
		default:
			return state;
	}
};

export default audits;