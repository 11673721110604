import React from "react";
import { Link } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import backgroundImage from "assets/img/login-background.jpeg";

// core components
import GridContainer from "core/components/Grid/GridContainer.js";
import GridItem from "core/components/Grid/GridItem.js";
import CustomInput from "core/components/CustomInput/CustomInput.js";
import Button from "core/components/CustomButtons/Button.js";
import Card from "core/components/Card/Card.js";
import CardBody from "core/components/Card/CardBody.js";
import CardHeader from "core/components/Card/CardHeader.js";
import CardFooter from "core/components/Card/CardFooter.js";

import { verifyEmail, verifyPassword } from "variables/utilities.js";
import { useTitle } from "variables/utilities";

// styles that should be used
import styles from "core/assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function LoginPage(props) {
  useTitle("Exaspaces, sign into the platform");

  const { basic, valid, onChangeHandler, onSubmitHandler } = props;

  // animation for the card entry
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 400);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  const classes = useStyles();
  return (
    <div>
      <div
        className={classes.fullPage}
        style={{
          backgroundImage: "url(" + backgroundImage + ")",
          backgroundPosition: "inherit",
        }}
      >
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={6} md={4}>
              <form>
                <Card login className={classes[cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}>
                      <h1 className={classes.cardH2Title}>Sign In</h1>
                      <h5 className={classes.cardTitle}>Broker Platform</h5>
                  </CardHeader>
                  <CardBody>
                    <CustomInput
                      labelText="Email"
                      id="login-email-id"
                      success={valid.email === true}
                      error={valid.email === false}
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment> ),
                        onChange: event => {
                          const valid = verifyEmail(event.target.value);
                          onChangeHandler(event, 'email', 'basic', valid);
                        },
                        value: basic.email
                      }}
                    />

                    <CustomInput
                      labelText="Password"
                      id="login-pword-user"
                      success={valid.password === true}
                      error={valid.password === false}
                      formControlProps={{
                        fullWidth: true,
                        required: true,
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Icon className={classes.inputAdornmentIcon}>
                              lock_outline
                          </Icon>
                          </InputAdornment> ),
                        onChange: event => {
                          const valid = verifyPassword(event.target.value);
                          onChangeHandler(event, 'password', 'basic', valid);
                        },
                        type: "password",
                        autoComplete: "off",
                        value: basic.password
                      }}
                    />
                  </CardBody>
                  
                  <CardFooter className={classes.justifyContentCenter}>
                    <GridContainer justifyContent="center">
                      <GridItem xs={12} sm={12}>
                        <div className={classes.center}>
                          <p className={classes.description + " " + classes.textCenter}>
                            By clicking on SIGN IN, you are agreeing to the&nbsp;
                            <Link to={process.env.REACT_APP_TOS} target="_blank">Terms and conditions</Link>&nbsp;&&nbsp;
                            <Link to={process.env.REACT_APP_PP} target="_blank">Privacy policy</Link>
                          </p>
                        </div>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.center}>
                          <Button
                            round 
                            fullWidth
                            color="purple"
                            disabled={valid.email === false || valid.password === false}
                            onClick={onSubmitHandler}>
                            Sign In
                          </Button>
                          <p></p>
                        </div>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={12}>
                        <div className={classes.center}>
                          <p>
                          <Link
                            to="/website/reset"
                            color="primary">
                             Forgot password?
                          </Link>
                          </p>

                        </div>
                      </GridItem>

                    </GridContainer>
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
