import {
  container,
  cardTitle,
  whiteColor,
  blackColor,
  grayColor,
  purpleColor,
  hexToRgb
} from "core/assets/jss/material-dashboard-pro-react.js";

import customCheckboxRadioSwitch from "core/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const loginPageStyle = theme => ({
  ...customCheckboxRadioSwitch,
  container: {
    ...container,
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "100px"
    }
  },
  cardTitle: {
    ...cardTitle,
    fontWeight: "500",
    color: purpleColor[0]
  },
  cardH2Title: {
    ...cardTitle,
    fontSize: "2.6em",
    fontWeight: "500",
    color: purpleColor[0]
  },
  textCenter: {
    fontWeight: "600",
    textAlign: "center"
  },
  justifyContentCenter: {
    justifyContent: "center !important"
  },
  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor
    },
    marginLeft: "5px",
    marginRight: "5px"
  },
  inputAdornment: {
    marginRight: "18px"
  },
  inputAdornmentIcon: {
    color: grayColor[6]
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  cardHeader: {
    marginBottom: "20px"
  },
  socialLine: {
    padding: "0.9375rem 0"
  },
  checkboxLabel: {
    fontSize: "0.875rem",
    marginLeft: "6px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)"
  },
  fullPage: {
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    color: whiteColor,
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      minHeight: "700px!important"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important"
    },
    "&:before": {
      // backgroundColor: "rgba(" + hexToRgb(whiteColor) + ", 0.15)"
    },
    "&:before,&:after": {
      display: "block",
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "2"
    }
  }  
});

export default loginPageStyle;
