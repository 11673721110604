import * as types from "store/types.js";
import { update } from 'variables/utilities';

const initial = {
  userId: null,
  loading: false, request: null,
  error: null
};

const started = (state, action) => {
  return update( state, {
          request: action.request,
          error: null, loading: true 
  });
};

const succeeded = (state, action) => {
  return update( state, {
          userId: action.userId,
          error: null, loading: false
   });
};

const failed = (state, action) => {
  return update( state, {
          error: action.error,
          loading: false
  });
}

const logout = (state, action) => {
  return update(state, { 
                userId: null 
  });
};

const authorize = (state = initial, action) => {
  switch ( action.type ) {
    case types.AUTHORIZE_START: 
        return started(state, action);

    case types.AUTHORIZE_SUCCESS: 
        return succeeded(state, action);

    case types.AUTHORIZE_FAILURE: 
        return failed(state, action);

    case types.AUTHORIZE_LOGOUT: 
        return logout(state, action);
        
    // case types.REDIRECT_PATH: 
    //     return redirect(state, action);
    default:
        return state;
  }
};

export default authorize;