import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {

};

const building = (state, action) => {
	return update( state, {
      buildingname: action.buildingname,
			buildingfilters: action.buildingfilters,
			modifiedDate: new Date(),
	});
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.BUILDING_SEARCH: 
      return building(state, action);
      
		default:
			return state;
	}
};

export default reducer;