import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false, notify: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const partners = action[action.partnerType] ? 
											action[action.partnerType] : state[action.partnerType];
	
	const index = action.index >= 0 ? action.index : state.index;
	const updatedPartners = action.request === 'create' ? [action.updated, ...partners] : partners;
	const updatedIndex =  action.request === 'create' ? 0 : index;
	if(action.request === 'update' && state.index >= 0) {
		updatedPartners[state.index] = action.updated;
	}

	return update( state, {
		[action.partnerType]: updatedPartners, 
		updated: action.updated,
		index: updatedIndex,
		modifiedDate: action.modifiedDate ? action.modifiedDate : state.modifiedDate,
		loading: false, notify: true
	});
};

const removed = (state, action) => {
	const partners = state[action.partnerType];
	const index = state.index ? state.index[0]: -1;
	if(index >= 0 && partners) {
		partners.splice(index, 1);
	}
	
	return update(state, {
		[action.partnerType]: partners,
		updated: null,
		modifiedDate: action.modifiedDate,
		loading: false, notify: true
	});
}

const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.PARTNER_START: 
			return started(state, action);

		case types.PARTNER_SUCCESS: 
			return succeeded(state, action);

		case types.PARTNER_REMOVE: 
			return removed(state, action);

			case types.PARTNER_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default reducer;