import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
  settings: {}, request: null,
	loading: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	return update( state, {
		settings: action.settings ? action.settings : state.settings,
		loading: false, modifiedDate: new Date(),
	});
};

const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const settings = (state = initial, action) => {
	switch ( action.type ) {
		case types.SETTINGS_START: 
			return started(state, action);

		case types.SETTINGS_SUCCESS: 
			return succeeded(state, action);

		case types.SETTINGS_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default settings;