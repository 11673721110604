import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	profile: null, error: null,
	loading: null, request: null
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const addons = action.addons ? action.addons : state.addons;
	if(action.index >= 0 ) {
		addons[action.index]=action.addon;
	}
	
	return update( state, {
		addons: addons,
		modifiedDate: action.modifiedDate,
	});
};

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.ADDON_START: 
			return started(state, action);
				
		case types.ADDON_SUCCESS: 
			return succeeded(state, action);
				
		case types.ADDON_FAILURE: 
			return failed(state, action);
				
		default:
			return state;
	}
};

export default reducer;