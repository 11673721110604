import React from "react";
import { Switch, Route } from "react-router-dom";
import classNames from "classnames";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// echelon components
import Notice from "layouts/components/Notice.js";
import Header from "core/components/LandingHeader/LandingHeader.js"
import HeaderLinks from "core/components/LandingHeader/LandingHeaderLinks.js";
import Explore from "core/components/Explore/Explore.js";
import Footer from "core/components/Footer/Footer.js"
import Recaptcha from "common/components/Captcha/Recaptcha.js";
import CallToAction from "components/Social/CallToAction";

// routes
import routes from "../../routes/webRoutes";

import styles from "core/assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
const useStyles = makeStyles(styles);

var ps;
export default function WebLayoutView(props) {
  const { history, paths=["/website/signin", "/website/signup", "/website/reset"] } = props;

  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
      window.addEventListener("resize", resizeFunction);
    }

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.addEventListener("resize", resizeFunction);
    };
  });

  const resizeFunction = () => {
  };

  const classes = useStyles();
  const mainPanelClasses = classNames({
    [classes.mainPanelWeb]: true,
    [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
  });

  return (
    <div className={classes.wrapper}>
      <Recaptcha />
      <Notice />
      <div id="adminMainPanel" className={mainPanelClasses} ref={mainPanel}>
        <Header
          color="white"
          links={<HeaderLinks 
                  dropdownHoverColor="info"
                  localsignin
                  demo="/broker/demo"
                  history={history}
                />}
          fixed
          {...props}
        />
        <Switch>
          {routes.map((route, key) => {
            return (
              <Route
                path={`${route.layout}${route.path}`}
                render={(props) => <route.component {...props} {...route.props} />}
                key={key}
              />
            );
          })}
        </Switch>
        {!paths.includes(history.location.pathname) &&
          <Explore base="broker" black {...props} /> 
        }
        <Footer black website />
        <CallToAction />
      </div>
    </div>
  )
}