import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false, unit: null,
};

const started = (state, action) => {
	const units = (state.request === 'retrieve') 
												? action.units : state.units;
	return update( state, {
		request: action.request,
		units: units,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const units = (state.request === 'retrieve') 
												? action.units : state.units;
	const modifiedDate = (state.request === 'retrieve') 
												? new Date() : state.modifiedDate;

  if(action.units) {
    // need to merge the changes with the list...  
	}
	return update( state, {
		modifiedDate: modifiedDate,
		units: units,
		unit: action.unit,
		loading: false, notify: true
	});
};

const removed = (state, action) => {
	return update( state, {
		unit: null, loading: false
	});
}

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const unit = (state = initial, action) => {
	switch ( action.type ) {
		case types.UNIT_START: 
			return started(state, action);

		case types.UNIT_SUCCESS: 
			return succeeded(state, action);

		case types.UNIT_REMOVE: 
			return removed(state, action);

		case types.UNIT_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default unit;