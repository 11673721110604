import buttonGroupStyle from "core/assets/jss/material-dashboard-pro-react/buttonGroupStyle.js";
import customCheckboxRadioSwitch from "core/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
  cardTitle,
  primaryColor,
  grayColor,
  tooltip
} from "core/assets/jss/material-dashboard-pro-react.js";

const extendedTablesStyle = theme => ({
  ...customCheckboxRadioSwitch,
  ...buttonGroupStyle,
  right: {
    textAlign: "right"
  },
  center: {
    textAlign: "center"
  },
  description: {
    maxWidth: "150px"
  },
  descriptionLarge: {
    maxWidth: "420px"
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  largeicon: {
    verticalAlign: "middle",
    width: "22px",
    height: "22px",
    top: "-1px",
    position: "relative"
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block"
  },
  img: {
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    border: "0"
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em"
  },
  tdNameAnchor: {
    color: grayColor[2]
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300"
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "145px",
    fontWeight: "300",
    fontSize: "1.3em !important"
  },
  tdNumberSmall: {
    marginRight: "3px"
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important"
  },
  titleLarge: {
    [theme.breakpoints.up("md")]: {
      minWidth: "320px"
    }
  },
  positionAbsolute: {
    position: "absolute",
    right: "0",
    top: "0"
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButtonRound: {
    width: "auto",
    height: "auto",
    minWidth: "auto"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardBodyCompact: {
    marginTop: "-20px",
    marginBottom: "0px",
    padding: "0px 20px",
  },
  cardBodySemiCompact: {
    marginTop: "-10px",
    marginBottom: "0px",
    padding: "0px 20px",
  },
  leftToolbarItems: {
    minWidth: "300px",
    maxWidth: "6000px",
  },
  grow: {
    flex: 1,
  },
  toolbarTextMargin: {
    paddingRight: "16px"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + "!important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    },
    "& + p": {
      fontWeight: "300"
    }
  },
  input: {
    color: grayColor[14],
    width: "64px",
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: grayColor[3]
    }
  },
  tooltip: {
    ...tooltip,
    minWidth: "80px",
  },  
});

export default extendedTablesStyle;
