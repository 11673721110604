import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
  paths: null, path: null, modified: 0,
};

const show = (state, action) => {
  return update( state, {
    values: {},
    routes: action.routes,
    base: action.base,
    filter: action.filter,
    modified: action.modified,
  });
};

const updatecrum = (state, action) => {
  return update( state, {
    routes: action.routes ? action.routes : state.routes,
    values: action.values,
    filter: action.filter,
    modified: action.modified,
  });
};

const crumbs = (state = initial, action) => {
  switch ( action.type ) {
      case types.BREADCRUM_SHOW: 
          return show(state, action);

      case types.BREADCRUM_UPDATE: 
          return updatecrum(state, action);

      default:
          return state;
  }
};

export default crumbs;