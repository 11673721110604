import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const proposals = (state.request === 'retrieve' && action.proposals !== null) 
												? action.proposals : state.proposals;
	const addedproposals = state.request === 'create' ? [action.proposal, ...proposals] : proposals;
	return update( state, {
		proposals: addedproposals,
		proposal: action.proposal,
		modifiedDate: new Date(),
		loading: false
	});
};

const limitssucceeded = (state, action) => {
	return update( state, {
		limits: action.limits,
		limitsModifiedDate: new Date(),
	})
}

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const removed = (state, action) => {
	return update( state, {
		proposal: null, loading: false
	});
}

const proposals = (state = initial, action) => {
	switch ( action.type ) {
		case types.PROPOSAL_START: 
			return started(state, action);

		case types.PROPOSAL_SUCCESS: 
			return succeeded(state, action);

		case types.PROPOSAL_LIMITS: 
			return limitssucceeded(state, action);

		case types.PROPOSAL_REMOVE: 
			return removed(state, action);

		case types.PROPOSAL_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default proposals;