import axios from "store/axios-broker";
import * as types from "store/types.js";
import { notifyFailure } from "store/actions/notice";

const started = (request) => {
  return {
      type: types.AUTHORIZE_START,
      request: request,
      error: null
  };
};

const succeeded = (userId) => {
  return {
      type: types.AUTHORIZE_SUCCESS,
      userId: userId,
  };
};

const failed = (error) => {
  return {
      type: types.AUTHORIZE_FAILURE,
      error: error && error.data && error.data.code ? error.data.code : "000"
  };
};

export const logout = () => {
  ['authorized', 'token', 'userId', 'businessId', 'freetrial',
    'role', 'modules', 'features', 'readonlymodules', 'propertymoduletypes'].forEach(element => {
    localStorage.removeItem(element);
  });

  return {
      type: types.AUTHORIZE_LOGOUT
  };
};

const querystring = require('querystring');
export const authorize = (email, password) => {
  return dispatch => {
      dispatch(started('authorize'));
      const body = {
          alias: email,
          password: password
      };
      axios.get(process.env.REACT_APP_GEO_API_ENDPOINT, { apptype: false })
      .then(response => {
        const { data } = response;
        const headers = { Address: data.ip, City: data.city, Region: data.region, Provider: data.org };
        let url = '/apex/certify/token/' + email + "/password";
        axios.post(url, querystring.stringify(body), {urlencoded: true, login: true, geoheaders: headers})
        .then(response => {
            let userProfile = JSON.parse(response.data.id_token);
            if(userProfile.employee !== null) {
              const { traits } = userProfile;
              localStorage.setItem('authorized', "true");
              const { access_token } = response.data;
              if(process.env.NODE_ENV === "development" && access_token !== null) {
                localStorage.setItem('token', access_token);
              }
              localStorage.setItem('userName', userProfile.name);
              localStorage.setItem('userId', userProfile.identifier);
              localStorage.setItem('businessId', userProfile.employee.businessId);
              localStorage.setItem('role', userProfile.employee.role);
              localStorage.setItem('modules', JSON.stringify(traits.modules));
              localStorage.setItem('features', JSON.stringify(traits.features));
              localStorage.setItem('readonlymodules', JSON.stringify(traits.readonlymodules));
              localStorage.setItem('propertymoduletypes', JSON.stringify(traits.propertymoduletypes));
              localStorage.setItem('freetrial', traits.freetrial);
              dispatch(succeeded(userProfile.identifier));
            } else {
              dispatch(failed( { data: { code: 'NOTACTIVE'}}));
              dispatch(notifyFailure('NOTACTIVE'));
            }
        });
      }).catch(error => {
        dispatch(failed(error.response));
      });
  };
};

export const checkAuthorized = () => {
  return dispatch => {
      const authorized = localStorage.getItem('authorized');
      if (authorized) {
        const userId = localStorage.getItem('userId');
        dispatch(succeeded(userId));
      } else {
        dispatch(logout());
      }
  };
};
