// @material-ui/icons

import Login from "views/Website/Entry/Login";
import Register from "views/Website/Entry/Register";
import ResetPassword from "views/Website/Entry/ResetPassword";
import BrokerPlatform from "views/Website/Platform/BrokerPlatform";
import Demo from "views/Website/Entry/Demo/Demo";

var webRoutes = [
  {
    path: "/demo",
    component: Demo,
    layout: process.env.REACT_APP_BROKER_PLATFORM
  },
  {
    path: "/reset",
    component: ResetPassword,
    layout: process.env.REACT_APP_BROKER_PLATFORM
  },
  {
    path: "/freetrial/signup",
    component: Register,
    props: { freetrial: true },
    layout: process.env.REACT_APP_BROKER_PLATFORM
  },
  {
    path: "/signup",
    component: Register,
    layout: process.env.REACT_APP_BROKER_PLATFORM
  },
  {
    path: "/signin",
    component: Login,
    layout: process.env.REACT_APP_BROKER_PLATFORM
  },
  {
    path: "",
    component: BrokerPlatform,
    props: { base: "broker" },
    layout: process.env.REACT_APP_BROKER_PLATFORM
  },
];
export default webRoutes;
