export const PROPERTY_TYPES = ["Office", "Retail", "Industrial", "Coworking space", "Business center", "Land", "Residential"];
export const COWORKING_TYPES = ["Coworking space", "Business center"];
export const STANDARD_TYPES = ["Office", "Retail", "Industrial"];
export const NOMERGE__TYPES =  ["Coworking space", "Business center", "Residential"];

export const AVAILABILITY = ["Available", "Not available"];

export const AMENITIES = ["24*7 security & surveillance", "Professional house-keeping & facility management", "Productivity services",
                          "Community services", "Beverages", "Pantry", "Office stationery", "Print-outs", "IT & audio-visual infrastructure",
                          "Community lounge", "Discussion booths", "Storage space", "IT support", "Utility monthly bills", "Power back-up",
                          "A/c system", "Car parking", "2 wheeler parking", "Common area/lounge", "Phone booth", "Partitions", 
                          "Reception/Front desk management", "Signage", "Logo on the glass", "Additional a/c charges", 
                          "Additional internet charges", "Additional operational hours charges"];

export const MUST_AMENITIES = ["Office stationery", "Print-outs", "Documentation", "IT support services", "Business support services", 
                          "Secure networks with Back-up", "LAN connection", "Wifi", "Disaster recovery", "Folding meeting tables", 
                          "Projectors", "Housekeeping", "Front desk", "Utility bills included in rent",  "Wellness room", 
                          "Event platforms", "Access to events", "Complimentary meeting rooms", "Concierge desk", "Business address"];

export const RESIDENTIAL_AMENITIES = ["Community hall", "Gym", "Yoga center", "Party hall", "Swimming pool", "Toddler's pool",
                                        "Multipurpose hall", "Indoor games room", "Children indoor games room", "Spa", "Sauna & Jaccuzzi", 
                                        "AV room/Video games room", "Jogging and walking track", "Outdoor kids play area", "Landscaped gardens", 
                                        "Association room", "Wifi clubhouse", "CCTV", "Sandpit", "Barbeque facility", "STP", "Rain water harvesting", 
                                        "Snooker room", "Aerobics floor", "Virtal golf simulator", "Basket ball court", "Reflexology pathway", 
                                        "Zen garden", "Rock garden", "Amphitheater", "Gazebo", "Bus bay area for school bus"]
                                    .sort((a, b) => a.localeCompare(b));

export const SEATING_TYPE = ["Suite/dedicated office", "Private office", "Hot desk/free desk", "Open seating"];
export const SPACE_CLASSIFICATION = ["Regular", "Standard", "Premium", "Customizable"];
export const FACILITY_TYPES = ["Meeting rooms", "Discussion rooms", "Event areas", "Video conference rooms", "Reception", 
                                "Lounge", "Phone booth"];
export const UTILITIES = ["Electricity back-up", "Exclusive Internet facility", "Fire systems"];

export const OPTIONS = {
  // Basic information drop-downs
  propertyTypes: PROPERTY_TYPES,
  retailTypes: ["Upmarket", "Mid-market"],
  buildingTypes: ["Campus", "Standalone"],
  developmentTypes: ["Independent house", "Layout", "Apartment complex", "Gated community", "Integrated township"],
  residenceTypes: ["Independent house", "Row houses", "Villas", "Low-rise apartments", "High-rise apartments"],
  houseTypes: ["1 BHK", "2 BHK", "3 BHK", "4+ BHK", "Studio", "Duplex", "Triplex", "Penthouse"],
  landTypes: ["Layout", "Standalone"],
  classification: ["Commercial", "IT Park", "SEZ"],
  classificationType: {	Office: ["Commercial", "IT Park", "SEZ"], 
                        Retail: ["High Street", "Shopping Centre", "Mall", "IT Park"],
                        Industrial: ["Warehouse space", "Industrial space", 
                                      "Free trade warehouse zone", "Container freight stations"],
                        "Coworking space": ["Commercial", "IT Park", "SEZ"],
                        "Business center": ["Commercial", "IT Park", "SEZ"],
                        "Land": ["Agriculture", "Commercial", "Industrial", "Residential"],
                        "Residential": ["Budget", "Standard", "Premium", "Semi-luxury", "Luxury", "Ultra-luxury"],
                      },
  furnishingTypes: {
    "Residential": ["Bare space", "Semi-furnished", "Furnished"],
    Office: ["Bare Shell", "Warm Shell", "Extended Warm Shell", "Partly Furnished", "Fully Furnished"],
    Retail: ["Bare Shell", "Warm Shell", "Furnished"],
    Industrial: ['Shell']
  },
  amenities: ["Cafeteria", "Landscape garden", "Break-out areas", "Valet parking", "Branding areas", "Car wash services", 
              "Event management areas", "Florist", "Food Court", "Creche", "ATM", "Banks", "Gym", "Convention center", 
              "Clinics", "Pharmacy", "Help desk", "Visitor parking", "Dedicated taxi bays", "Fire tender bays", 
              "Ambulance bays", "Laundry services", "Fine dine restaurants"],
  landamenities: ["Landscape parks", "Walking/jogging tracks", "Shuttle courts", "Children playpark", "Senior citizen sit-out area", 
                  "Pergola", "Compound wall", "Electricity supply attached", "Corporation water supply", "Security", 
                  "Elevated black top roads", "LED street lights", "Ready for construction", "Avenue trees"],
  residentialamenities: RESIDENTIAL_AMENITIES,
  landRankByType: {"Industrial": ["Red", "Orange", "Green"],
                    "Agriculture": ["Wet land", "Dry land"],
                    "Commercial": [],
                    "Residential": []
                  },
  grades: ["Grade A+", "Grade A", "Grade B", "Grade C"],
  status: ["Completed", "Under construction", "Proposed project"],
  landStatus: ["Undeveloped", "Under development", "Ready to occupy"],
  landCategory: ["Government allotted", "Privately owned"],
  landUseZones: ["Residential zone", "Commercial zone", "Industrial zone", "Entertainment zone", 
                "Eco-friendly zone", "Eco-sensitive zone", "Mixed Zone"],
  landSuitabilityTypes: ["Villa development", "Row house", "Resort", "Hill station", "Farm house", "Beach house", 
                          "Independent house", "Office building", "Showroom", "Hotel development", "Clinic/Lab", 
                          "Hospital", "Factory", "Warehouse", "Stockpoint", "Apartments"],
  structureType: ["Single level", "Multi level"],
  zonelocations: [],

  areaUnitTypes: ["Sqft", "Sq yd", "Sqm", "Cents", "Acres", "Hectares"],

  // Utilities drop-downs
  carParkTypes: ["Basement", "Open", "Covered", "Stilt", "Mechanically covered", "Multi-level"],
  truckParkTypes: ["Open", "Covered"],
  liftTypes: ['Passenger', "Service"],
  fireSystemTypes: ["Sprinklers", "Fire sump", "Hydrants"],
  acSystemTypes:["Central", "Unit-wise", "Package units"],
  flooringTypes: ["FM2 grade", " FM3 grade", "Expoxy", "Concrete screed"],
  additionalInfra:["Earthing", "Lightning arrestors", "External ramps", "External fire hydrant lines", 
                  "Skylight panels", "Passive ventilation systems"],
  containerTypes: ["20' General", "20' High Cube", "20' Flat Rack (w/ sides)", "20' Flat Rack (w/o sides)", "20' Open Top", "20' Reefer",
                  "40' General", "40' High Cube", "40' Flat Rack (w/ sides)", "40' Flat Rack (w/o sides)", "40' Open Top", "40' Reefer"],
};

export const PROPERTY_TYPE_TRANSACTION_OPTIONS = {
  "Office" : ["Lease", "Sale"], 
  "Retail" : ["Lease", "Sale"],
  "Industrial" : ["Lease", "Sale"], 
  "Coworking space" : ["Lease"], 
  "Business center" : ["Lease"],
  "Land" : ["Lease", "Sale", "JV", "JD", "JV+JD"],
  "Residential": ["Lease", "Sale"],
}

export const LAND_UNIT_OPTION = {
  "Sale": ["Sale", "BTS Sales"],
  "Lease": ["Lease", "BTS Lease"],
  "JV" : ["JV"],
  "JD" : ["JD"],
  "JV+JD" : ["JV+JD"]
}

export const AREACONVERSIONTABLE = {
  "sqft": 1, "sq yd": 9, "sqm": 10.7639, "cents": 435.6000, "acres": 43560.0000, "hectares": 107639.0000
};
export const AREAROUNDINGTABLE = {
  "sqft": 0, "sq yd": 0, "sqm": 2, "cents": 2, "acres": 4, "hectares": 4
};
export const convertToSquareFeet = (area, unit) => {
  if(area && unit) {
    return +area * +AREACONVERSIONTABLE[unit.toLowerCase()];
  }
  return 0;
}

export const convertFromSquareFeet = (area, unit) => {
  if(area && unit) {
    const unitconversion = AREACONVERSIONTABLE[unit.toLowerCase()];
    return +area / +(unitconversion ? unitconversion : 1);
  }
  return 0;
}

export const computeCostPerSquareFeet = (cost, unit) => {
  if(cost && unit) {
    return +cost / +AREACONVERSIONTABLE[unit.toLowerCase()];
  }
  return 0;
}

export const toFixed = (value, unit) => {
  if(value && unit) {
    const decimals = AREAROUNDINGTABLE[unit.toLowerCase()];
    if(decimals) {
      return value.toFixed(decimals);
    }
  }
  return value;
}