import * as types from "store/types";

const initial = {
  loading: false,
};

const progress = (state = initial, action) => {
  switch ( action.type ) {
      case types.ARTIFACT_START:
      case types.DOCUMENT_START:
          return {...state, modifiedDate: new Date(), loading: true}

      case types.ARTIFACT_SUCCESS: 
      case types.ARTIFACT_REMOVE: 
      case types.ARTIFACT_FAILURE:
      case types.DOCUMENT_END:
      return {...state, modifiedDate: new Date(), loading: false}

      default:
          return state;
  }
};

export default progress;