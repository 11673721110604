import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
	loading: false, 
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const tenants = state.request === 'retrieve' ? action.tenants : state.tenants;
	const modifiedDate = (state.request === 'retrieve') ? new Date() : state.modifiedDate;

	return update( state, {
		tenants: tenants,
		tenant: action.tenant,
		modifiedDate: modifiedDate,
		index: action.index >= 0 ? action.index : state.index,
		loading: false
	});
};

const removed = (state, action) => {	
	return update(state, {
		tenant: null,
		loading: false
	});
}

const failed = (state, action) => {
	return update( state, {
			error: action.error, loading: false
	});
}

const reducer = (state = initial, action) => {
	switch ( action.type ) {
		case types.TENANT_START: 
			return started(state, action);

		case types.TENANT_SUCCESS: 
			return succeeded(state, action);

		case types.TENANT_REMOVE: 
			return removed(state, action);

			case types.TENANT_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default reducer;